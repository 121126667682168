<template>
  <div id="app" class="w-full overflow-x-hidden">
    <div class="min-h-screen w-full px-4">
      <NavigationBar :sections="sections" :current-section-id="currentSectionId"/>
      <HeroSection />
      <AboutSection />
      <WorkExperienceSection :skills="skills"/>
      <SkillsSection :skills="skills"/>
      <ProjectsSection :skills="skills"/>
      <FooterSection />
      <!-- <HelloWorld msg="Welcome to Your Vue.js App" name="Vlad" :items="items" /> -->
    </div>
  </div>

</template>

<script>
  import NavigationBar from './components/NavigationBar.vue';
  import AboutSection from './components/sections/AboutSection.vue';
  import FooterSection from './components/sections/FooterSection.vue';
  import HeroSection from './components/sections/HeroSection.vue';
  import ProjectsSection from './components/sections/ProjectsSection.vue';
  import SkillsSection from './components/sections/SkillsSection.vue';
  import WorkExperienceSection from './components/sections/WorkExperienceSection.vue';
  import { initFlowbite } from 'flowbite'
  import 'animate.css';

  export default {
    name: 'App',
    components: {
      NavigationBar,
      HeroSection,
      AboutSection,
      WorkExperienceSection,
      SkillsSection,
      ProjectsSection,
      FooterSection
    },
    data() {
      return {
        skills: {
          programmingLanguages: [
            {
              label: 'JavaScript',
              icon: 'https://i.postimg.cc/pXJtM0RK/js.png'
            },
            {
              label: 'TypeScript',
              icon: 'https://i.postimg.cc/3rjqkdVT/typescript.png'
            },
            { label: 'Java', icon: 'https://i.postimg.cc/DyTKDVk2/java.png' },
            { label: 'Php', icon: 'https://i.postimg.cc/66CkCH7F/php.png' },
            {
              label: 'Python',
              icon: 'https://i.postimg.cc/yYP2bkyP/python.png'
            },
            { label: 'HTML5', icon: 'https://i.postimg.cc/QN5308z4/html.png' },
            { label: 'CSS3', icon: 'https://i.postimg.cc/nhnpXy0d/css.png' }
          ],
          libraries: [
            {
              label: 'Spring Boot',
              icon: 'https://i.postimg.cc/4yGF8gKq/spring-boot.png'
            },
            { label: 'React', icon: 'https://i.postimg.cc/QddvGh6r/react.png' },
            { label: 'Vue', icon: 'https://i.postimg.cc/m2b5CTWF/vue.png' },
            { label: 'Next', icon: 'https://i.postimg.cc/g05CHQHb/nextjs.png' },
            { label: 'Nuxt', icon: 'https://i.postimg.cc/j26BK50R/nuxt.png' },
            { label: 'Lit', icon: 'https://i.postimg.cc/jS80LLW5/litjs.png' },
            {
              label: 'Android',
              icon: 'https://i.postimg.cc/P5KhYS7d/android.png'
            },
            {
              label: 'Laravel',
              icon: 'https://i.postimg.cc/4x4Rfzxq/laravel.png'
            },
            { label: 'Bulma', icon: 'https://i.postimg.cc/vmByzWRf/bulma.png' },
            {
              label: 'Tailwind',
              icon: 'https://i.postimg.cc/Y0xDZsY9/tailwind.png'
            },
            { label: 'Sass', icon: 'https://i.postimg.cc/43KMywkL/sass.png' }
          ],
          tools: [
            { label: 'Git', icon: 'https://i.postimg.cc/MHk8qNnm/git.png' },
            {
              label: 'Github',
              icon: 'https://i.postimg.cc/PJmnxyZs/github.png'
            },
            {
              label: 'Bitbucket',
              icon: 'https://i.postimg.cc/PqRjFPhc/bitbucket.png'
            },
            {
              label: 'Docker',
              icon: 'https://i.postimg.cc/B6P0W4nv/docker.png'
            },
            {
              label: 'Postgres',
              icon: 'https://i.postimg.cc/5N1ZgwLy/postgres.png'
            },
            { label: 'Nginx', icon: 'https://i.postimg.cc/d3cgzCfW/nginx.png' },
            {
              label: 'Liquibase',
              icon: 'https://i.postimg.cc/43mCtst1/liquibase.png'
            },
            { label: 'Redis', icon: 'https://i.postimg.cc/x1ph9h7N/redis.png' },
            { label: 'Slack', icon: 'https://i.postimg.cc/C1kQH1Kb/slack.png' },
            { label: 'Teams', icon: 'https://i.postimg.cc/NfpPxZQC/teams.png' },
            { label: 'Skype', icon: 'https://i.postimg.cc/8CqY6WT1/skype.png' },
            {
              label: 'Microsoft SQL Server',
              icon: 'https://i.postimg.cc/C1qWP7cB/mssql.png'
            }
          ]
        },
        sections: [
          {
            id: 'hero-section',
            label: 'Home'
          },
          {
            id: 'about-section',
            label: 'About'
          },
          {
            id: 'work-experience-section',
            label: 'Work Experience'
          },
          {
            id: 'skills-section',
            label: 'Skills'
          },
          {
            id: 'projects-section',
            label: 'Projects'
          },
          {
            id: 'footer-section',
            label: 'Contacts'
          }
        ],
        currentSectionId: ''
      } 
    },
    mounted() {
      initFlowbite();
      document.title = "Murimi's Portfolio";
      window.addEventListener('scroll', this.onPageScrollListener);
      this.getCurrentSectionFromHash();
    },
    beforeMount() {
      window.removeEventListener('scroll', this.onPageScrollListener);
    },
    methods: {
      onPageScrollListener() {
        this.sections.forEach(section => {
          if (window.scrollY >= document.getElementById(section.id).getBoundingClientRect().top) {
            this.currentSectionId = section.id;
          }
        })
      },
      getCurrentSectionFromHash() {
        const hash = window.location.hash;
        if (hash) {
          this.currentSectionId = this.sections.find(section => section.id === hash.replace('#', '')).id;
        }
        return null;
      }
    }
  }
</script>

<style>
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
  }
</style>
