<template>
  <div id="skills-section" class="w-full flex flex-wrap mt-8 pt-20 px-4">
    <h3 class="text-3xl font-bold dark:text-white mb-4 w-full">Skills</h3>
    <div class="sm:hidden  w-full">
      <label for="tabs" class="sr-only">Select your country</label>
      <select id="tabs" v-model="selectedTabId" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
        <option v-for="(tab, index) in tabs" :key="index" :value="tab.id">{{ tab.label }}</option>
      </select>
    </div>
    <div class="hidden sm:block w-full">
      <nav class="flex items-center justify-center w-full" aria-label="Tabs">
        <button v-for="(tab, index) in tabs" :key="index" :class="{'text-blue-700 bg-white border-blue-700': selectedTabId === tab.id, 'text-gray-500 hover:text-gray-700 hover:bg-gray-50': selectedTabId !== tab.id}" class="px-3 py-2 border font-medium text-md rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-offset-gray-100 focus-visible:ring-blue-500" 
          @click="onTabClickHandler(tab.id)" :id="`tab-${index}`" :aria-controls="`tabpanel-${index}`" :aria-selected="selectedTabId === tab.id">{{ tab.label }}</button>
      </nav>
    </div>
    <div id="tabs-contents" class="w-full mt-4 animate__animated mb-4">
      <div class="w-full flex flex-wrap px-2 pt-2">
        <div class="w-full flex flex-wrap px-2">
          <div class="grid grid-cols-3 gap-4 w-full">
            <div v-for="(item, index) in skills[filteredTab.id]" :key="index" class="flex flex-col justify-center items-center p-2">
              <img :src="item.icon" class="w-14 h-14 transition-all duration-300  cursor-pointer filter md:grayscale hover:grayscale-0" alt="icon">
              <p class="text-sm text-center">{{ item.label }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  const tabs = [{
    label: "Programming Languages",
    id: "programmingLanguages"
  }, {
    label: "Libraries & Frameworks",
    id: "libraries"
  }, {
    label: "Tools",
    id: "tools"
  }];
  export default {
    props: { skills: Object },
    name: 'SkillsSection',
    data() {
      return {
        tabs,
        selectedTabId: tabs[0].id
      }
    },
    computed: {
      filteredTab() {
        return this.tabs.find(tab => tab.id === this.selectedTabId);
      }
    },
    methods: {
      onTabClickHandler(tab) {
        const tabContents = document.querySelector("#tabs-contents");
        if(tab !== this.selectedTabId) {
          tabContents.classList.add("animate__zoomOut");
          setTimeout(() => {
            this.selectedTabId = tab;
            tabContents.classList.remove("animate__zoomOut");
            tabContents.classList.add("animate__zoomIn");
          }, 300);
        }

      }
    }
  }
</script>
<style scoped>
  #tabs-contents {
    height: 60vh;
    overflow-y: auto;
  }
</style>