<template>
	<div id="about-section" class="w-full flex flex-wrap px-2 pt-20 mb-6 ">
		<h3 class="text-3xl font-bold dark:text-white mb-4 w-full">About Me</h3>
		<p class="text-lg text-gray-500 dark:text-gray-400 pl-2">
			I’m a full stack developer with a deep passion for solving business, brand and social challenges through software development. I have over 3 years' experience developing software for products including inventory management, bulk SMS, payment gateways, hotel management, POS, banking services, and human resource solutions. 
		<br>
I have very good understanding of dev ops having setup cloud computing environments from providers such as AWS, Microsoft Azure, Digital Ocean, and Contabo all on Linux servers, services such as Docker, Nginx, and Apache, CI /CD tools such as Github actions and domain configurations on Cloudflare.  
		</p>
	</div>
</template>

<script>
	export default {
		name: 'AboutSection',
	}
</script>