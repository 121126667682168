
<template>
  <footer id="footer-section" class="bg-white dark:bg-gray-900 pt-20">
    <div class="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
      <div class="sm:flex sm:items-center sm:justify-between">
        <span class="text-sm text-gray-500 sm:text-center dark:text-gray-400">© {{ currentYear }} Murimi Njiru. All Rights Reserved.
        </span>
        <div class="flex mt-4 sm:justify-center sm:mt-0">
          <a href="tel:+254746452213" target="_blank" class="text-gray-500 hover:text-gray-900 dark:hover:text-white">
            <i class="fa-solid fa-phone w-4 h-4"></i>
            <span class="sr-only">Phone Number</span>
          </a>
          <a href="mailto:muriminjiru@hotmail.com" target="_blank" class="text-gray-500 hover:text-gray-900 dark:hover:text-white ms-5">
            <i class="fa-solid fa-envelope w-4 h-4"></i>
            <span class="sr-only">Email</span>
          </a>
          <a href="https://www.linkedin.com/in/murimi-njiru-851195204/" target="_blank" class="text-gray-500 hover:text-gray-900 dark:hover:text-white ms-5">
            <i class="fa-brands fa-linkedin w-4 h-4"></i>
            
            <span class="sr-only">LinkedIn</span>
          </a>
          <a href="https://github.com/Murimi-Njiru" target="_blank" class="text-gray-500 hover:text-gray-900 dark:hover:text-white ms-5">
            <i class="fa-brands fa-github w-4 h-4"></i>
            <span class="sr-only">Github</span>
          </a>
          <a href="https://x.com/KarisNjiru" target="_blank" class="text-gray-500 hover:text-gray-900 dark:hover:text-white ms-5">
            <i class="fa-brands fa-square-x-twitter w-4 h-4"></i>
            <span class="sr-only">X</span>
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>

  export default {
    name: 'FooterSection',
    computed: {
      currentYear() {
        return new Date().getFullYear();
      }
    }
  }
</script>